/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EngTitle } from "components/atoms/EngTitle/EngTitle";
import { Panel } from "components/atoms/FadeInText/FadeInText";
import { SlashLineSeparator } from "components/atoms/SlashSeparator/SlashLineSeparator";
import { FadeInTexts } from "components/molecules/FadeInTexts/FadeInTexts";
import { useTheme } from "hooks/useTheme";
import { useCallback, useState } from "react";
import ReactPageScroller from "react-page-scroller";
import { Link } from "react-router-dom";
import { ANIMATAION_DURATION_SM, mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FadeInByComponent } from "utils/Animation/FadeInByComponent";
import { constants } from "utils/constants";
import { MainLayout } from "../MainLayout/MainLayout";

const Separator: React.FC = () => (
  <SlashLineSeparator mx={defaultTheme.spacing.xxs} size={0.75} />
);

const PanelItem: React.FC<{
  text: React.ReactNode;
  hideSeparator?: boolean;
}> = ({ text, hideSeparator = false }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    <span
      css={css`
        ${mixin.text.note};
        @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
          ${mixin.text.description};
        }

        letter-spacing: 0.2em;
        white-space: nowrap;
        text-transform: uppercase;
      `}
    >
      {text}
    </span>
    {!hideSeparator && <Separator />}
  </div>
);

const imageSources = {
  products: {
    kawaichiPortfolio: require("assets/images/home/homepage-products-01.webp"),
    talkstock: require("assets/images/home/homepage-products-02.webp"),
    kawaichiBlog: require("assets/images/home/homepage-products-03.webp"),
    fitscreenwindow: require("assets/images/home/homepage-products-04.webp"),
    batchfilerenamer: require("assets/images/home/homepage-products-05.webp"),
    shotoku: require("assets/images/home/homepage-products-06.webp"),
    dtpdesign: require("assets/images/home/homepage-products-07.webp"),
  },
};

type TitleAndPanelsProps = {
  animate: boolean;
  title: string;
  panels: Panel[];
};

const TitleAndPanels: React.FC<TitleAndPanelsProps> = ({
  animate,
  title,
  panels,
}) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `}
  >
    <FadeInByComponent direction="bottom" positionY={40} animate={animate}>
      <div
        css={css`
          width: 100%;
          display: flex;
          align-items: flex-start;
          text-align: left;
        `}
      >
        <EngTitle
          cssOverride={css`
            ${mixin.changeThemeFontSize};

            // 単語単位で折り返す
            word-break: normal;
            line-height: 1 !important;

            display: flex;
            justify-content: flex-start;

            font-size: 10vw;
            @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
              font-size: 10vw;
            }
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              font-size: 5.5rem;
            }

            margin-bottom: 1rem;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              margin-bottom: 1rem;
            }
          `}
        >
          {title}
        </EngTitle>
      </div>
    </FadeInByComponent>
    <div
      css={css`
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      `}
    >
      <FadeInTexts
        panels={panels}
        delayTimeSec={0}
        cssOverride={css`
          display: flex;
          flex-wrap: wrap;
        `}
      />
    </div>
  </div>
);

const StyledImg: React.FC<{
  src: string;
  alt: string;
  animate: boolean;
}> = ({ src, alt, animate }) => (
  <FadeInByComponent direction="spot" animate={animate}>
    <img
      src={src}
      alt={alt}
      css={css`
        max-width: 100%;
        max-height: 400px;

        transition-duration: 300ms;
        opacity: 0.75;
        transform: scale(1);
        filter: brightness(0.9);
        &:hover {
          transition-duration: 300ms;
          opacity: 1;
          transform: scale(1.1);
          filter: brightness(0.95);
        }
      `}
    />
  </FadeInByComponent>
);

const FullPageContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div
      css={css`
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      {children}
    </div>
  );
};

export type ScreenComponentItem = {
  title: string;
  requireSrc: string;
  alt: string;
  href: string;
  panels: Panel[];
  isAnchorLink?: boolean;
  animate?: boolean;
};

type Props = ScreenComponentItem;

const ScreenComponent: React.FC<Props> = ({
  requireSrc,
  alt,
  title,
  href,
  panels,
  isAnchorLink = false,
  animate = false,
}) => {
  return (
    <FullPageContainer>
      <div
        css={css`
          position: relative;

          max-width: 100%;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            max-width: 1000px;
            width: 100%;
          }
        `}
      >
        {isAnchorLink ? (
          <a href={href} target="_blank" rel="noreferrer">
            <StyledImg src={requireSrc} alt={alt} animate={animate} />
          </a>
        ) : (
          <Link to={href}>
            <StyledImg src={requireSrc} alt={alt} animate={animate} />
          </Link>
        )}

        <div
          css={css`
            // サイドマージン
            margin: 0 1.5rem;
            @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
              margin: 0 3.75rem;
            }
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              margin: 0 0;
              margin: 0 3.75rem;
            }
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 90%;
            `}
          >
            <TitleAndPanels animate={animate} title={title} panels={panels} />
          </div>
        </div>
      </div>
    </FullPageContainer>
  );
};

const PageIndicator: React.FC<{ currentPage: number; totalPages: number }> = ({
  currentPage,
  totalPages,
}) => {
  return (
    <div>
      <span
        css={css`
          ${mixin.changeThemeFontSize};
          font-family: "Raleway";
          font-weight: 700;

          font-size: 1.5rem;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            font-size: 2rem;
          }
        `}
      >
        0{currentPage + 1}
      </span>
      <SlashLineSeparator mx="0.5rem" size={0.75} />
      <span
        css={css`
          ${mixin.changeThemeFontSize};
          font-family: "Raleway";
          font-weight: 700;

          font-size: 0.75rem;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            font-size: 1rem;
          }
        `}
      >
        0{totalPages}
      </span>
    </div>
  );
};

const HomePage: React.FC = () => {
  const { theme } = useTheme();
  const [currentPage, setCurrentPage] = useState(0);

  const screenComponentItems: ScreenComponentItem[] = [
    {
      title: "PORTFOLIO SITE",
      requireSrc: imageSources.products.kawaichiPortfolio,
      alt: "KAWAICHI PORTFOLIO",
      href: constants.pages.product.url.portfolio,
      panels: [
        { value: <PanelItem text="React" /> },
        { value: <PanelItem text="TypeScript" /> },
        { value: <PanelItem text="Storybook" /> },
        { value: <PanelItem text="AWS" /> },
        { value: <PanelItem text="Terraform" hideSeparator /> },
      ],
    },
    {
      title: "WEB SERVICE",
      requireSrc: imageSources.products.talkstock,
      alt: "トークストック",
      href: constants.pages.product.url.talkstock,
      panels: [
        { value: <PanelItem text="Next.js" /> },
        { value: <PanelItem text="TypeScript" /> },
        { value: <PanelItem text="Redux" /> },
        { value: <PanelItem text="Echo" /> },
        { value: <PanelItem text="Go" /> },
        { value: <PanelItem text="AWS" /> },
        { value: <PanelItem text="Terraform" hideSeparator /> },
      ],
    },
    {
      title: "ENGINEER BLOG",
      requireSrc: imageSources.products.kawaichiBlog,
      alt: "かわいちのエンジニアぶろぐ！",
      href: constants.pages.product.url.kawaichiBlog,
      panels: [
        { value: <PanelItem text="Next.js" /> },
        { value: <PanelItem text="TypeScript" /> },
        { value: <PanelItem text="GraphQL" /> },
        { value: <PanelItem text="AWS" /> },
        { value: <PanelItem text="Terraform" hideSeparator /> },
      ],
    },

    {
      title: "WINDOWS APP",
      requireSrc: imageSources.products.fitscreenwindow,
      alt: "FitScreenWindow",
      href: constants.pages.product.url.fitscreenwindow,
      panels: [
        { value: <PanelItem text="Python" /> },
        { value: <PanelItem text="Qt" /> },
        { value: <PanelItem text="NSIS" /> },
        { value: <PanelItem text="Vue.js" hideSeparator /> },
      ],
    },
    {
      title: "CLI TOOL",
      requireSrc: imageSources.products.batchfilerenamer,
      alt: "BatchFileRenamer",
      href: constants.links.batchfilerenamer,
      isAnchorLink: true,
      panels: [
        { value: <PanelItem text="GO" /> },
        { value: <PanelItem text="Vue.js" hideSeparator /> },
      ],
    },
    {
      title: "VBA MACRO",
      requireSrc: imageSources.products.shotoku,
      alt: "所得拡大促進税制エクセルソフト",
      href: constants.pages.product.url.shotoku,
      panels: [
        { value: <PanelItem text="Excel VBA" /> },
        { value: <PanelItem text="MySQL" hideSeparator /> },
      ],
    },
    {
      title: "DTP DESIGN",
      requireSrc: imageSources.products.dtpdesign,
      alt: "DTPデザイン",
      href: constants.pages.design.url,
      panels: [
        { value: <PanelItem text="Illustrator" /> },
        { value: <PanelItem text="Photoshop" hideSeparator /> },
      ],
    },
  ];

  const pagesNumbers = screenComponentItems.map((_, index) => index);
  const [animate, setAnimate] = useState(
    screenComponentItems.map((_, index) => index === 0)
  );

  const handleBeforePageChange = useCallback((number: number) => {
    setCurrentPage(number);

    // アニメーションのリセット
    setAnimate(screenComponentItems.map((_, index) => index === number));
  }, []);

  const handleAfterPageChange = useCallback((number: number) => {}, []);

  const handleScrollUnavailable = useCallback(() => {
    if (currentPage === 0) {
      setCurrentPage(pagesNumbers.length - 1);

      return;
    }
    if (currentPage === pagesNumbers.length - 1) {
      setCurrentPage(0);
      return;
    }
  }, [currentPage]);

  // HACK: Template化する(HomeTemplate)
  return (
    <div>
      <div
        css={css`
          position: fixed;
          top: 50%;
          left: ${defaultTheme.spacing.base.x.mobile};
          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            left: ${defaultTheme.spacing.base.x.pc};
          }

          transform: translateY(-50%);
          z-index: ${defaultTheme.zIndex.pageNumber};
        `}
      >
        <PageIndicator
          currentPage={currentPage}
          totalPages={pagesNumbers.length}
        />
      </div>
      <ReactPageScroller
        onBeforePageScroll={handleBeforePageChange}
        renderAllPagesOnFirstRender
        pageOnChange={handleAfterPageChange}
        customPageNumber={currentPage}
        animationTimer={ANIMATAION_DURATION_SM}
        animationTimerBuffer={500} // スクロールでページを切り替えてからスクロールを禁止させる時間
        handleScrollUnavailable={handleScrollUnavailable}
        //blockScrollUp={currentPage === 0}
        //blockScrollDown={currentPage === pagesNumbers.length - 1}
        transitionTimingFunction="ease-in-out"
      >
        {screenComponentItems.map((item, index) => (
          <ScreenComponent
            key={index}
            animate={animate[index]}
            title={item.title}
            requireSrc={item.requireSrc}
            alt={item.alt}
            href={item.href}
            isAnchorLink={item.isAnchorLink}
            panels={item.panels}
          />
        ))}
      </ReactPageScroller>
    </div>
  );
};

const LayoutPage = () => {
  return (
    <MainLayout hiddenFooter>
      <HomePage />
    </MainLayout>
  );
};

export { LayoutPage as HomePage };
