/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { ComponentLibraryButton } from "components/atoms/ComponentLibraryButton/ComponentLibraryButton";
import { Copyright } from "components/atoms/Copyright/Copyright";
import { GradationDivider } from "components/atoms/GradationDivider/GradationDivider";
import { LinkText } from "components/atoms/LinkText/LinkText";
import {
  SNSSymbolIconProps,
  SNSSymbolIcons,
} from "components/molecules/SNSSymbolIcons/SNSSymbolIcons";
import React, { useEffect } from "react";
import {
  ANIMATAION_DURATION_LG,
  ANIMATAION_DURATION_MD,
  mixin,
} from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FadeInByComponent } from "utils/Animation/FadeInByComponent";
import { constants } from "utils/constants";

type Props = {
  iconProps: SNSSymbolIconProps;
  uiLibraryLink: string;
  color?: string;
  cssOverride?: SerializedStyles;
  hiddenComponentLibraryButton?: boolean;
  hiddenDivider?: boolean;
  animate?: boolean;
};

export const NavFooter: React.FC<Props> = ({
  iconProps,
  uiLibraryLink,
  color = "#666666",
  cssOverride,
  hiddenComponentLibraryButton = false,
  hiddenDivider = false,
  animate = true,
}) => {
  const [animateState, setAnimateState] = React.useState({
    sns: false,
    copyright: false,
  });
  useEffect(() => {
    if (!animate) {
      setAnimateState({
        sns: false,
        copyright: false,
      });
      return;
    }

    setTimeout(() => {
      setAnimateState((prev) => ({
        ...prev,
        sns: true,
      }));
    }, ANIMATAION_DURATION_MD);

    setTimeout(() => {
      setAnimateState((prev) => ({
        ...prev,
        copyright: true,
      }));
    }, ANIMATAION_DURATION_LG);
  }, [animate]);

  return (
    <div css={[cssOverride]}>
      {/* ComponentLibrary */}
      {!hiddenComponentLibraryButton && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <a href={uiLibraryLink} target="_blank" rel="noreferrer">
            <ComponentLibraryButton />
          </a>
        </div>
      )}
      {/* Divider */}
      {!hiddenDivider && (
        <GradationDivider
          fromColor={defaultTheme.palette.common.white}
          toColor={defaultTheme.palette.gray.button[100]}
          size={defaultTheme.borderWidth.sm}
          cssOverride={css`
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: ${hiddenComponentLibraryButton
              ? 0
              : defaultTheme.spacing.sm};
            margin-bottom: ${defaultTheme.spacing.sm};

            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 240px;
            }
          `}
        />
      )}
      {/* SNS */}
      <FadeInByComponent
        direction="bottom"
        animate={animateState.sns}
        positionY={40}
        cssOverride={css`
          width: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        <div
          css={css`
            margin-bottom: ${defaultTheme.spacing.sm};
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <SNSSymbolIcons color={color} iconProps={iconProps} />
        </div>
      </FadeInByComponent>

      {/* Copyright */}
      <FadeInByComponent
        direction="spot"
        animate={animateState.copyright}
        cssOverride={css`
          width: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        <div
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: ${defaultTheme.spacing.xxs};
          `}
        >
          <div
            css={css`
              display: block;
              display: flex;
              gap: ${defaultTheme.spacing.xxxs};
              align-items: center;
              flex-direction: row;
            `}
          >
            <LinkText
              mode="router"
              item={{
                value: constants.pages.privacyPolicy.title,
                href: constants.pages.privacyPolicy.url,
              }}
              cssOverride={css`
                ${mixin.text.note}
              `}
              styleType="reverse"
            />
          </div>
          <Copyright
            cssOverride={css`
              color: ${defaultTheme.palette.gray.button[100]};
            `}
          />
        </div>
      </FadeInByComponent>
    </div>
  );
};
