/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { Copyright } from "components/atoms/Copyright/Copyright";
import { GradationDivider } from "components/atoms/GradationDivider/GradationDivider";
import { LinkText } from "components/atoms/LinkText/LinkText";
import { VerticalLineSeparator } from "components/atoms/VerticalLineSeparator/VerticalLineSeparator";
import { PageTop, PageTopProps } from "components/molecules/PageTop/PageTop";
import {
  SNSSymbolIcons,
  SNSSymbolIconsProps,
} from "components/molecules/SNSSymbolIcons/SNSSymbolIcons";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { constants } from "utils/constants";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { SiteMap } from "../SiteMap/SiteMap";

const LINK_TEXT_STYLETYPE = "reverse";

export type FooterSlotProps = {
  pageTop?: PageTopProps & { show?: boolean };
  wrapper: {
    bgColor: string;
    textColor: string;
    style?: SerializedStyles;
  };
  snsIcons: SNSSymbolIconsProps;
};

type Props = {
  slotProps: FooterSlotProps;
  id?: string;
  mediaQuery: MediaQuery;
};

export const Footer: React.FC<Props> = ({
  slotProps,
  id = "footer",
  mediaQuery,
}) => {
  return (
    <>
      {slotProps?.pageTop?.show && (
        <PageTop
          bgColor={slotProps?.pageTop?.bgColor}
          color={slotProps?.pageTop?.color}
          cssOverride={slotProps?.pageTop?.cssOverride}
          styleType={slotProps?.pageTop?.styleType}
        />
      )}
      <div
        id={id}
        css={css`
          ${mixin.changeTheme};
          ${slotProps.wrapper.style};

          width: 100%;
          margin: 0 auto;
          text-align: center;
          background-color: ${slotProps.wrapper.bgColor};
          color: ${slotProps.wrapper.textColor};
        `}
      >
        <BaseInner width="100%">
          <SiteMap
            color={slotProps.wrapper.textColor}
            mediaQuery={mediaQuery}
          />
          <div
            css={css`
              margin-top: ${defaultTheme.spacing.lg};
              margin-bottom: ${defaultTheme.spacing.lg};
            `}
          >
            <GradationDivider
              size={defaultTheme.borderWidth.sm}
              disableAnimation
            />
          </div>

          <div
            css={css`
              display: flex;
              flex-wrap: nowrap;
              gap: ${defaultTheme.spacing.xs};

              flex-direction: column;
              align-items: center;
              @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                flex-direction: row;
                justify-content: space-between;
              }
            `}
          >
            <Copyright
              cssOverride={css`
                display: none;
                @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                  display: block;
                  // MEMO: プライバシーポリシーとの視覚上のベースライン合わせ調整用
                  position: relative;
                  top: -0.1rem;
                }
              `}
            />
            <div
              css={css`
                display: flex;
                gap: ${defaultTheme.spacing.xs};
                align-items: center;

                flex-direction: column;
                @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                  flex-direction: row;
                }
              `}
            >
              <div
                css={css`
                  display: none;
                  @media screen and (min-width: ${defaultTheme.breakpoints
                      .md}) {
                    display: block;
                    display: flex;
                    gap: ${defaultTheme.spacing.xxxs};
                    align-items: center;

                    flex-direction: row;
                  }
                `}
              >
                <LinkText
                  mode="router"
                  item={{
                    value: "お問い合わせ",
                    href: constants.pages.contact.url,
                  }}
                  cssOverride={css`
                    ${mixin.text.note};

                    display: none;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      display: block;
                    }
                  `}
                  styleType={LINK_TEXT_STYLETYPE}
                />
                <VerticalLineSeparator size={0.8} />
                <LinkText
                  mode="router"
                  item={{
                    value: constants.pages.privacyPolicy.title,
                    href: constants.pages.privacyPolicy.url,
                  }}
                  cssOverride={css`
                    ${mixin.text.note};

                    display: none;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      display: block;
                    }
                  `}
                  styleType={LINK_TEXT_STYLETYPE}
                />
              </div>

              <SNSSymbolIcons
                iconProps={slotProps.snsIcons.iconProps}
                color={slotProps.snsIcons.color}
              />
              <div
                css={css`
                  display: flex;
                  gap: ${defaultTheme.spacing.xxxs};
                  align-items: center;
                  flex-direction: row;

                  @media screen and (min-width: ${defaultTheme.breakpoints
                      .md}) {
                    display: none;
                  }
                `}
              >
                <LinkText
                  mode="router"
                  item={{
                    value: "お問い合わせ",
                    href: constants.pages.contact.url,
                  }}
                  cssOverride={css`
                    ${mixin.text.note};

                    display: block;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      display: none;
                    }
                  `}
                  styleType={LINK_TEXT_STYLETYPE}
                />
                <VerticalLineSeparator size={0.8} />
                <LinkText
                  mode="router"
                  item={{
                    value: constants.pages.privacyPolicy.title,
                    href: constants.pages.privacyPolicy.url,
                  }}
                  cssOverride={css`
                    ${mixin.text.note};

                    display: block;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      display: none;
                    }
                  `}
                  styleType={LINK_TEXT_STYLETYPE}
                />
              </div>
            </div>

            <Copyright
              cssOverride={css`
                display: block;
                @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                  display: none;
                }
              `}
            />
          </div>
        </BaseInner>
      </div>
    </>
  );
};
