/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useTheme } from "hooks/useTheme";
import React, { useEffect } from "react";
import { CursorIndicator } from "./CursorIndicator";

const useCursorIndicator = () => {
  /* オリジナルマウスカーソル */
  useEffect(() => {
    // FIXME: cursorがnullの場合があるため修正する(なぜか404ページでは正常動作する)
    //カーソル用のdivタグを取得
    const cursor = document.getElementById("cursor");

    //divタグをマウスに追従させる
    document.addEventListener("mousemove", function (e) {
      if (cursor) {
        cursor.style.transform =
          "translate(" + e.clientX + "px, " + e.clientY + "px)";
      }
    });

    //リンクにホバー時はクラスをつける
    const linkElem = document.querySelectorAll("a");
    for (let i = 0; i < linkElem.length; i++) {
      linkElem[i].addEventListener("mouseover", function (e) {
        if (cursor) {
          cursor.classList.add("hov_");
        }
      });
      linkElem[i].addEventListener("mouseout", function (e) {
        if (cursor) {
          cursor.classList.remove("hov_");
        }
      });
    }
  }, []);
};

export const CursorIndicatorContainer: React.FC = () => {
  const { theme } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  useCursorIndicator();

  return (
    <CursorIndicator
      color={theme.palette.primary}
      hoverColor={theme.palette.gray.button[100]}
      size={12}
      hoverSize={48}
      cssOverride={css`
        ${mediaQuery.pc ? "" : "display: none;"}
      `}
    />
  );
};
