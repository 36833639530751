import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ANIMATAION_DURATION_MD } from "styles/mixin";
import { sleep } from "utils/sleep";

type Config = {
  showPagePaths: string[];
};

export const useToggleShowByPagePaths = ({ showPagePaths }: Config) => {
  const location = useLocation();
  const [show, setShow] = React.useState<boolean>(false);

  useEffect(() => {
    const toggleShow = async () => {
      await sleep(ANIMATAION_DURATION_MD);
      setShow(showPagePaths.includes(location.pathname));
    };
    if (location.pathname) {
      toggleShow();
    }
  }, [location.pathname]);

  return {
    show,
  };
};
